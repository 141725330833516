export const SCREEN_RESIZE = 'SCREEN_RESIZE'
export const FETCH_TOTALS = 'FETCH_TOTALS'
export const FETCH_USER = 'FETCH_USER'
export const OPEN_MODAL = 'OPEN_MODAL'
export const REFRESH_MAP = 'REFRESH_MAP'
export const INFO_WINDOW = 'INFO_WINDOW'
export const SEARCH_OPENMATS = 'SEARCH_OPENMATS'
export const CREATE_OPENMAT = 'CREATE_OPENMAT'
export const SHOW_OPENMAT = 'SHOW_OPENMAT'
export const SEARCH_GYMS = 'SEARCH_GYMS'
export const SHOW_GYM = 'SHOW_GYM'
export const RESET_MAT_ID = 'RESET_MAT_ID'
export const CLEAR_PREVIOUS = 'CLEAR_PREVIOUS'
export const UPDATE_OPENMAT = 'UPDATE_OPENMAT'
export const UPDATE_GYM = 'UPDATE_GYM'
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS'
export const SORT_BY = 'SORT_BY'